import { baseUrl } from "@/config";

class _ExportServices {

    DownloadExport(fileId: string, name: string) {
        window.open(baseUrl + `api/Export/DownloadExport?fileId=${fileId}&name=${name}`, '_blank');
    }
    
}

export let ExportServices = new _ExportServices();
