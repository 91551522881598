
import { Options, Vue } from 'vue-class-component';
import { GeneralSurveyClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class AnswersToSurvey extends Vue {

    surveyIdentifier: string = "";
    items: OM.GeneralSurveyAnswerToQuestionVM[] = [];

    created(){
        this.surveyIdentifier = this.$route.params.identifier as string;
        this.init();
    }

    init(){
        if(!this.surveyIdentifier)
            return;

        GeneralSurveyClient.getAllAnswersToSurvey(this.surveyIdentifier)
        .then( x => {
            this.items = x;
        })
    }

    exportExcel(){
        GeneralSurveyClient.exportAnswersToOpenSurvey(this.surveyIdentifier)
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.text);
        })
    }

}
